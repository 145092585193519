<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >

          <el-form-item label="设备类型:" prop="devType">
            <el-select
              v-model="searchModel.devType"
              placeholder="请选择设备类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value,key) in devTypeOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称:">
            <el-input
              v-model="searchModel.devName"
              placeholder="输入设备名称"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-close"
              v-if="loading"
              @click="loading = false"
            >停止</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="concreteDevice"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :data="tableData"
        >
          <template #buttons>
            <el-button
              v-auth="'数据直报_设备维护_新增设备'"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="dialogDevEdit = true;"
            >新增设备</el-button
            >
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <!--        <vxe-table-column
                                field="compName"
                                title="所属公司"
                                show-overflow="title"
                                min-width="150"
                                show-header-overflow="title"
                        />-->
          <vxe-table-column type="expand" width="30">
            <template #default>
            </template>
            <template #content="{ row }">
              <div class="expand-wrapper">
                <vxe-table ref="qTable" :data="row.detailDtoRows" class="qtable-style">
                  <vxe-table-column type="seq" title="序号" width="60" />
                  <vxe-table-column
                    field="devType"
                    title="设备类型"
                    show-overflow="title"
                    min-width="100"
                    show-header-overflow="title"
                    :formatter="({cellValue, row, column }) => { return devTypeOptions[cellValue]; }"/>
                  <vxe-table-column
                    field="devName"
                    title="设备名称"
                    show-overflow="title"
                    min-width="120"
                    show-header-overflow="title"/>
                  <template  v-if="row.devType ==1 || row.devType ==2">
                    <vxe-table-column
                      field="queueNum"
                      title="生产线数量"
                      show-overflow="title"
                      min-width="80"
                      show-header-overflow="title"/>
                    <vxe-table-column
                      field="yearThroughput"
                      title="年生产能力/万方"
                      show-overflow="title"
                      min-width="100"
                      show-header-overflow="title"/>
                    <vxe-table-column  v-for="column of queueColumns[row.devType]" :field="column.key" :title="column.key" show-overflow="title" min-width="80" :key="column.key" />
                  </template>
                  <template v-else>
                    <vxe-table-column
                      field="devNum"
                      title="设备数量/台"
                      show-overflow="title"
                      min-width="150"
                      show-header-overflow="title"/>
                    <vxe-table-column
                      field="ratedNum"
                      title="额定量/方机"
                      show-overflow="title"
                      min-width="150"
                      show-header-overflow="title" />
                    <vxe-table-column
                      field="actualNum"
                      title="实际作业量/方机"
                      show-overflow="title"
                      min-width="150"
                      show-header-overflow="title"/>
                  </template>

                  <vxe-table-column field="operationName" title="修改人" show-overflow="title" min-width="180"/>
                  <vxe-table-column field="isUpload" title="状态" show-overflow="title" min-width="80"  :formatter="({cellValue, row, column }) => { return cellValue==0?'未提交':'已提交'}"></vxe-table-column>
                  <vxe-table-column field="remark" title="备注" show-overflow="title" min-width="150" ></vxe-table-column>
                  <vxe-table-column title="操作" min-width="100">
                    <template v-slot="{ row }">
                      <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editSubRow(row)" v-if="row.isUpload == 0"  title="修改"/>
                      <el-button type="success" icon="el-icon-top" circle size="mini" @click="commitSubRow(row)" v-if="row.isUpload == 0" title="提交"/>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="devType"
            title="设备类型"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"
            :formatter="
              ({cellValue, row, column }) => {
                return devTypeOptions[cellValue];
              }
            "/>
          <vxe-table-column
            field="devName"
            title="设备名称"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"/>
          <vxe-table-column
            field="yearStartNum"
            title="年初数量"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"/>
          <vxe-table-column
            field="yearStartRatedNum"
            title="年初额定数量"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"/>
          <vxe-table-column
            field="currNum"
            title="当前数量"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"/>
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deletetRow(row)" title="删除"/>
              <el-button type="success" icon="el-icon-circle-plus-outline" circle size="mini" @click="addSubRow(row)" title="新增记录"/>
              <el-button type="success" icon="el-icon-notebook-1" circle size="mini" @click="showOperLog(row)" title="操作日志"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <el-dialog :title="dialogDevTitle"
                 :visible.sync="dialogDevEdit"
                 :v-if="dialogDevEdit"
                 :append-to-body="true"
                 width="750px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="120px" style="margin-top: 20px">
          <el-form-item label="设备类型:">
            <el-select
              v-model="devEditModel.devType" props="devType"
              placeholder="请选择设备类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value,key) in devTypeOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称:">
            <el-input
              v-model="devEditModel.devName"
              placeholder="默认与设备类型一致,可更改"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="年初数量:">
            <el-input
              v-model="devEditModel.yearStartNum"
              style="width: 200px"
            >
            </el-input>台
          </el-form-item>
          <el-form-item label="年初额定数量:">
            <el-input
              v-model="devEditModel.yearStartRatedNum"
              style="width: 180px"
            >
            </el-input>台
          </el-form-item>
          <el-form-item label="当前数量:">
            <el-input
              v-model="devEditModel.currNum"
              style="width: 200px"
            >
            </el-input>台
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDevEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitDevEdit()" size="mini">保存</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="dialogDevDetailTitle"
                 :visible.sync="dialogDevDetailEdit"
                 :v-if="dialogDevDetailEdit"
                 :append-to-body="true"
                 width="750px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="120px" style="margin-top: 20px">
          <el-form-item label="设备类型:">
            <el-select
              v-model="devDetailEditModel.devType" props="devType"
              placeholder="请选择设备类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value,key) in devTypeOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称:">
            <el-input
              v-model="devDetailEditModel.devName"
              placeholder="默认与设备类型一致,可更改"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="生产线数量:" v-if="devDetailEditModel.devType==1||devDetailEditModel.devType==2">
            <el-input
              v-model="devDetailEditModel.queueNum"
              style="width: 200px"
              @change="changeQueList(devDetailEditModel.queueNum)"
            >
            </el-input>台
          </el-form-item>
          <el-form-item label="年生产能力:"  v-if="devDetailEditModel.devType==1||devDetailEditModel.devType==2">
            <el-input
              v-model="devDetailEditModel.yearThroughput"
              style="width: 180px"
            >
            </el-input>万方
          </el-form-item>
          <el-form-item  v-for="item in devDetailEditModel.queueDetailList" :key="item.key"
                         :label="item.key">
            <el-input
              v-model="item.value"
              style="width: 160px"
            >
            </el-input>方机</el-form-item>
          <el-form-item label="设备数量:"  v-if="devDetailEditModel.devType.length>0&&devDetailEditModel.devType!=1&&devDetailEditModel.devType!=2">
            <el-input
              v-model="devDetailEditModel.devNum"
              style="width: 200px"
            >
            </el-input>台
          </el-form-item>
          <el-form-item label="额定量:"  v-if="devDetailEditModel.devType.length>0&&devDetailEditModel.devType!=1&&devDetailEditModel.devType!=2">
            <el-input
              v-model="devDetailEditModel.ratedNum"
              style="width: 200px"
            >
            </el-input>方机
          </el-form-item>
          <el-form-item label="实际作业量:" v-if="devDetailEditModel.devType.length>0&&devDetailEditModel.devType!=1&&devDetailEditModel.devType!=2">
            <el-input
              v-model="devDetailEditModel.actualNum"
              style="width: 200px"
            >
            </el-input>方机
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDevDetailEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitDevDetailEdit()" size="mini">保存</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="dialogOperLogTitle"
                 :visible.sync="dialogOperLog"
                 :v-if="dialogOperLog"
                 :append-to-body="true"
                 width="1200px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <vxe-table
          ref="logTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :data="logTableData"
        >
          <vxe-table-column type="seq" title="序号" width="60" />
          <!--        <vxe-table-column
                                field="compName"
                                title="所属公司"
                                show-overflow="title"
                                min-width="150"
                                show-header-overflow="title"
                        />-->
          <vxe-table-column
            field="devType"
            title="设备类型"
            show-overflow="title"
            min-width="100"
            show-header-overflow="title"
            :formatter="
              ({cellValue, row, column }) => {
                return devTypeOptions[cellValue];
              }
            "/>
          <vxe-table-column
            field="devName"
            title="设备名称"
            show-overflow="title"
            min-width="100"
            show-header-overflow="title"/>
          <vxe-colgroup title="新增/减少">
            <template  v-if="operateLogRow.devType ==1 || operateLogRow.devType ==2">
              <vxe-table-column
                field="logQueueNum"
                title="生产线数量"
                show-overflow="title"
                min-width="100"
                show-header-overflow="title"/>
              <vxe-table-column
                field="logYearThroughput"
                title="年生产能力/万方"
                show-overflow="title"
                min-width="100"
                show-header-overflow="title"/>
              <vxe-table-column  v-for="column of queueColumns[operateLogRow.devType]" :field="column.key" :title="column.key" show-overflow="title" min-width="60" :key="column.key" />
            </template>
            <template v-else>
              <vxe-table-column
                field="logDevNum"
                title="设备数量/台"
                show-overflow="title"
                min-width="100"
                show-header-overflow="title"/>
              <vxe-table-column
                field="logRatedNum"
                title="额定量/方机"
                show-overflow="title"
                min-width="100"
                show-header-overflow="title" />
              <vxe-table-column
                field="logActualNum"
                title="实际作业量/方机"
                show-overflow="title"
                min-width="100"
                show-header-overflow="title"/>
            </template>
          </vxe-colgroup>
          <vxe-table-column field="operationDate" title="修改时间" show-overflow="title" min-width="60"/>
          <vxe-table-column field="operationName" title="修改人" show-overflow="title" min-width="60"/>
          <vxe-table-column field="remark" title="备注" show-overflow="title" min-width="60"/>
        </vxe-table>
      </el-dialog>
    </div>
  </page-container>
</template>

<script>
import { getDataUpleDevList, addOrUpdateDataUpleDev, deleteDataUpleDev, addOrUpdateDataUpleDevDetail, commitUpleDevDetail, getDataUpleDevLog } from '@/apis/dataReport'
import XEUtils from 'xe-utils'
export default {
  name: 'concrete-device',
  data () {
    return {
      logTableData: [],
      dialogOperLogTitle: '操作日志',
      dialogOperLog: false,
      devDetailEditModel: {
        actualNum: 0,
        dataUpleDevDetailId: 0,
        dataUpleDevId: 0,
        devName: '',
        devNum: 0,
        devType: '',
        queueDetailList: null,
        queueNum: 0,
        ratedNum: 0,
        yearThroughput: 0
      },
      dialogDevDetailEdit: false,
      dialogDevDetailTitle: '添加记录',
      dialogDevTitle: '添加设备',
      searchModel: {
        devName: '',
        devType: ''
      },
      dialogDevEdit: false,
      devTypeOptions: {
        1: '商砼生产线',
        2: '砂浆生产线',
        3: '搅拌车',
        4: '泵车',
        5: '砂浆车',
        6: '背罐车',
        7: '其他'
      },
      searchText: '查询',
      allAlign: 'center',
      tableData: [],
      loading: false,
      queueColumns: {},
      devEditModel: {
        devName: '',
        devType: '',
        yearStartNum: 0,
        yearStartRatedNum: 0,
        currNum: 0,
        dataUpleDevId: ''
      },
      modifyDetailRow: null,
      operateLogRow: {}
    }
  },
  computed: {
    // 机构 或 企业的 id
    companyParams () {
      const userInfo = this.$store.state.account.userInfo
      const params = {}
      params.upleCompId = userInfo.compId || undefined
      //  params.orgId = userInfo.orgaId || undefined
      return params
    }
  },
  methods: {
    changeQueList (curVal) {
      const queList = []
      if (this.devDetailEditModel.devType != 1 && this.devDetailEditModel.devType != 2) { return }
      if (this.modifyDetailRow && this.modifyDetailRow.queueNum == curVal) {
        this.devDetailEditModel.queueDetailList = this.modifyDetailRow.queueDetailList
      } else {
        for (let i = 1; i <= parseInt(curVal); i++) {
          queList.push({
            id: i,
            key: i + '号线',
            value: ''
          })
        }
        this.devDetailEditModel.queueDetailList = queList
      }
    },
    submitSearch () {
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    onSubmitDevEdit () {
      addOrUpdateDataUpleDev({
        ...this.devEditModel,
        ...this.companyParams
      }).then(res => {
        if (res.code === 200) {
          if (this.devEditModel.dataUpleDevId != '') { this.$message.success('修改成功！') } else { this.$message.success('添加成功！') }
          this.dialogDevEdit = false
          this.refreshData()
        } else { this.$message.error(res.msg) }
      })
    },
    // 添加设备
    addRow () {
      // const rowData = {}
      this.devEditModel = {
        devName: '',
        devType: '',
        yearStartNum: 0,
        yearStartRatedNum: 0,
        currNum: 0,
        dataUpleDevId: ''
      }
    },
    // 修改设备
    editRow (row) {
      this.dialogDevTitle = '修改设备'
      // 对象迭代器，只能用于遍历对象，性能高于 each
      const rowData = {}
      XEUtils.objectEach(this.devEditModel, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
      })
      this.devEditModel = Object.assign({}, rowData)
      this.dialogDevEdit = true
    },
    // 删除设备
    deletetRow (row) {
      deleteDataUpleDev({
        id: row.dataUpleDevId
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功！')
          this.refreshData()
        } else { this.$message.error('删除失败！') }
      })
    },
    // 设备操作日志
    showOperLog (row) {
      this.operateLogRow = row
      getDataUpleDevLog({
        dataUpleDevId: row.dataUpleDevId
      }).then(res => {
        if (res.code == 200) {
          // const rows = []
          res.data.forEach(row => {
            if (row.devType == 1 || row.devType == 2) {
              row.logQueueDetailList.forEach(queEve => {
                // code->value
                row[queEve.key] = queEve.value
              })
            }
          })
          this.logTableData = res.data
          this.dialogOperLog = true
        }
      })
    },
    onSubmitDevDetailEdit () {
      addOrUpdateDataUpleDevDetail({
        ...this.devDetailEditModel
      }).then(res => {
        if (res.code === 200) {
          if (this.devDetailEditModel.dataUpleDevDetailId != '') { this.$message.success('修改成功！') } else { this.$message.success('添加成功！') }
          this.dialogDevDetailEdit = false
          this.refreshData()
        } else { this.$message.error(res.msg) }
      })
    },
    // 新增记录
    addSubRow (row) {
      // const rowData = new Object()
      this.devDetailEditModel = {
        actualNum: 0,
        dataUpleDevDetailId: '',
        dataUpleDevId: row.dataUpleDevId,
        devName: '',
        devNum: 0,
        devType: '',
        queueDetailList: null,
        queueNum: 0,
        ratedNum: 0,
        yearThroughput: 0
      }
      this.dialogDevDetailEdit = true
    },
    // 编辑记录
    editSubRow (row) {
      this.modifyDetailRow = row
      this.dialogDevDetailTitle = '修改设备记录'
      /*   let rowData = new Object()
                XEUtils.objectEach(this.devDetailEditModel, (item, key) => {
                    if(key!='queueDetailList')
                        rowData[key] = row[key].toString()
                    else
                        rowData[key] = row[key]
                }) */
      this.devDetailEditModel = Object.assign({}, row)
      this.dialogDevDetailEdit = true
    },
    // 提交记录
    commitSubRow (row) {
      commitUpleDevDetail({ dataUpleDevDetailId: row.dataUpleDevDetailId }).then(res => {
        if (res.code === 200) {
          this.$message.success('提交成功！')
          this.refreshData()
        } else { this.$message.error(res.msg) }
      })
    },
    // 分页变化
    refreshData () { // todaymissing,alert单独处理
      this.loading = true
      getDataUpleDevList({
        ...this.searchModel
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          const qColumns = {}
          const contColumnsByType = {}
          data.forEach(rowGenarl => {
            // 清空列表数据 、列名数组
            const rows = []
            rowGenarl.detailDtoList.forEach((rowAssi, index) => {
              // expanded 行数据
              let rowData = {}
              rowData = Object.assign({}, rowAssi)
              if (rowAssi.devType == 1 || rowAssi.devType == 2) {
                rowAssi.queueDetailList.forEach(queEve => {
                  // code->value
                  rowData[queEve.key] = queEve.value
                  if (!contColumnsByType[rowAssi.devType]) {
                    contColumnsByType[rowAssi.devType] = []
                    contColumnsByType[rowAssi.devType].push(queEve.key)
                    qColumns[rowAssi.devType] = []
                    qColumns[rowAssi.devType].push({
                      key: queEve.key,
                      value: queEve.value
                    })
                  } else if (!XEUtils.includes(contColumnsByType[rowAssi.devType], queEve.key)) {
                    contColumnsByType[rowAssi.devType].push(queEve.key)
                    qColumns[rowAssi.devType].push({
                      key: queEve.key,
                      value: queEve.value
                    })
                  }
                })
              }
              rows.push(rowData)
            })
            rowGenarl.detailDtoRows = rows
          })
          this.queueColumns = qColumns
          this.tableData = data
        } else {
          this.tableData = []
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    }
  },
  mounted () {
    this.refreshData()
  }
}
</script>
